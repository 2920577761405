import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImg from "../components/common/BackgroundImg";
import InformationCard from "../components/common/InformationCard";
import CardContainer from "../components/common/CardContainer";
import informationDataFr from "../../static/content/information/informationFr.yml";
import informationDataEn from "../../static/content/information/informationEn.yml";
import PageTitle from "../components/common/PageTitle";
import Languages from "../referentials/Languages";

const PriceExample = ({ text, style }) => {
  return (
    <p className="bg-grey" style={style}>
      {text}
    </p>
  );
}

const PriceExampleList = ({ textArray, style }) => {
  let listJSXItems = null;
  if (Array.isArray(textArray) && textArray.length > 0) {
    listJSXItems = textArray.map((textElement, index) => {
      return (
        <PriceExample key={`price-example-${index}`} text={textElement} style={style} />
      );
    });
  }
  return (
    <React.Fragment>
      {listJSXItems}
    </React.Fragment>
  );
}

export default (props) => {
  const data = useStaticQuery(graphql`
  query informationImg {
    image: file(relativePath: {eq: "informationImg/train-montenvers.jpg"})  {
      childImageSharp {
        fluid(maxWidth: 3500, maxHeight: 1900, quality: 80, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);

  const priceCardStyle = {
    width: "fit-content",
    marginLeft: "4rem",
    padding: ".25rem 1rem",
    marginTop: "5px",
    marginBottom: "5px",
  };

  let informationData = null;
  switch (props.language) {
    case Languages.fr:
      informationData = informationDataFr;
      break;
    default:
      informationData = informationDataEn;
  }

  return (
    <>
      <BackgroundImg imgFluid={data.image.childImageSharp.fluid} />
      <CardContainer>
        <PageTitle {...props} />
        <InformationCard text={informationData.introduction} />
        <p className="bg-grey" style={priceCardStyle}>
          {informationData.dayPrices}
        </p>
        <InformationCard text={informationData.dayPricesExplaination} style={priceCardStyle} />
        <PriceExampleList textArray={informationData.dayPricesExamples} style={priceCardStyle} />
        <InformationCard text={informationData.epilogue} />
      </CardContainer>
    </>
  );
}